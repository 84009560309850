var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "messenger-actions" }, [
    _c("div", { staticClass: "messenger-btns" }, [
      _c("button", {
        staticClass: "status-btn1",
        domProps: { textContent: _vm._s(_vm.$t("Surplus")) },
        on: { click: _vm.data.settleup2 },
      }),
      _c("button", {
        staticClass: "status-btn2",
        domProps: { textContent: _vm._s(_vm.$t("Refund")) },
        on: { click: _vm.data.settleup },
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }